<template>
  <div>
    <b-card>
      <!-- <div
        class="employee-task d-flex justify-content-center align-items-center"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-img
              :src="require('@/assets/images/simiicons/Image1.png')"
              style="width: 100px"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <div>
              <span class="font-weight-bold d-block text-nowrap label">
                Saunder Ofner
              </span>
            </div>
            <small class="text-muted"> @saunderofner</small>
          </b-media-body>
        </b-media>
      </div> -->
      <!-- <div class="d-flex justify-content-end mt-0" />
      <div class="mb-1 d-block font-weight-bolder text-nowrap label">
        Profiles
      </div> -->
      <div>
        <app-collapse
          id="faq-payment-qna"
          accordion
          type="margin"
          class="mt-2"
        >
          <app-collapse-item
            v-for="(profile, index) in userProfiles"
            :id="profile.id"
            :key="index"
            :time="false"
            :title="profile.profile_name"
            :is-avatar="true"
            :switches="false"
            :bannerads="false"
            :editicon="false"
            :src="profile.profile_image"
            :edit="profiles"
          >
            <div class="heading-elements">
              <b-table
                :items="fetchData"
                responsive
                :fields="tableColumns"
                show-empty
              >
                <!-- Column: name -->
                <template
                  #cell(ALLOWED-APPS)="data"
                >
                  <span class="text-nowrap">
                    {{ data.item.AllowedApps }}
                  </span>
                  <div
                    v-if="data.item.status"
                    class="statuss font-weight-bold"
                    style="float: right"
                  >
                    {{ data.item.status }}
                  </div>
                  <div
                    v-if="data.item.Position"
                    class="position font-weight-bold"
                    style="float: right"
                  >
                    {{ data.item.Position }}
                  </div>
                </template>
              </b-table>
            </div>
            <div class="heading-elements">
              <b-table
                :items="widgesData"
                responsive
                :fields="widgesColumn"
                show-empty
              >
                <!-- Column: name -->
                <template
                  #cell(ALLOWED-WIDGETS)="data"
                >
                  <span class="text-nowrap">
                    {{ data.item.AllowedWidgets }}
                  </span>
                </template>
              </b-table>
            </div>
            <div class="heading-elements">
              <b-table
                :items="viewsData"
                responsive
                :fields="viewsColumn"
                show-empty
              >
                <!-- Column: name -->
                <template #cell(VIEWS)="data">
                  <span class="text-nowrap">
                    {{ data.item.Views }}
                  </span>
                  <vs-switch
                    circle
                    class="switch"
                    disabled
                    :style="`background-color: ${!data.item.status ? '#FF0000' : '#00E265'};color:white; `"
                    @click="func(data.item.status)"
                  >
                    <span
                      slot="on"
                      style="font-weight: bold"
                    >Disable</span>
                    <span
                      slot="off"
                      style="font-weight: bold"
                    >Enable</span>
                  </vs-switch>
                </template>
              </b-table>
            </div>
          </app-collapse-item>
        </app-collapse>
      </div>
      <div>
        <div class="text-nowrap" />
      </div>
    </b-card>
    <div
      v-if="isProfiles"
      id="loading-bg"
    >
      <div class="loading-logo">
        <img
          :src="require('@/assets/images/logo/loading-logo.svg')"
          alt="Logo"
        >
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>

import {
  BTable, VBToggle, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    AppCollapseItem,
    AppCollapse,
    BTable,
    BCard,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isProfiles: false,
      switch2: true,
      userProfiles: '',
      active: false,
      fetchData: [
        { Position: 'Home', AllowedApps: 'Instagram', status: 'Allowed Apps' },
        { AllowedApps: 'WhatsApp', status: 'Allowed Apps' },
        { AllowedApps: 'Facebook', status: 'Allowed Apps' },
        { AllowedApps: 'Messenger', status: 'Allowed Apps' },
      ],
      widgesData: [
        { AllowedWidgets: 'Calender', status: true },
        { AllowedWidgets: 'CamScanner' },
      ],
      viewsData: [
        { Views: 'Stocks', status: false },
        { Views: 'Exchange Rate', status: false },
        { Views: 'COVID', status: false },
      ],
      tableColumns: [{ key: 'ALLOWED-APPS' }],
      widgesColumn: [{ key: 'ALLOWED-WIDGETS' }],
      viewsColumn: [{ key: 'VIEWS' }],

      switchData: [{ AllowedApps: 'sam' }, { AllowedApps: 'qam' }],
    }
  },
  async mounted() {
    this.isProfiles = true
    await this.getAllUserProfiles()
    this.isProfiles = false
  },
  methods: {
    func(data) {
      if (data === 'true') return 'color: black'
      if (data === 'false') return 'color: red'
      return 'primary'
    },
    changeState() {
      this.active = false
    },
    async getAllUserProfiles() {
      this.$axios
        .get(`admin/profile/${this.$route.params.userId}`)
        .then(({ data }) => {
          const { data: dataRes } = data || {}
          this.userProfiles = dataRes
          this.profileId = this.userProfiles.map(d => d.id)
          // this.setCustomViewsStatuses(this.userProfiles)
        }).catch(error => ({ error }))
    },
    resolveUserStatusVariant(Status) {
      if (Status === 'true') return 'black'
      if (Status === 'false') return 'red'
      return 'primary'
    },
  },
}
</script>

<style>
.position {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f;
  border-radius: 25px;
  margin: auto;
  display: inline-block;
  padding: 5px 15px;
}
.switch {
  border-radius: 20px;
  float: right;
  color: #FFF3E8;
}
.statuss {
  color: #FF9F43;
  font-weight: 600;
  font-family: "Montserrat";
  border-radius: 25px;
  margin-left: 10px;
  display: inline-block;
  padding: 5px 15px;
  background-color: #FFF3E8;
}

</style>
